<template>
  <v-dialog
    v-model="referredOnDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        title="Visitering af sag"
        v-on="on"
        @click="referredOnDialogInit"
      >
        <v-icon dark>
          mdi-calendar-clock
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Visitering af sag
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />

        <v-container
          v-if="loaded && referredOnModel"
          fluid
        >
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="referredOnModel.data.referredOn"
                use-iso
                label="Evt. tidspunkt..."
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Aktuelt tildelt: <br>
              {{ referredOnModel.data.currentAssignedTo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="referredOnModel.data.newAssignedToId"
                :items="referredOnModel.metadata.assignedTos"
                item-text="text"
                item-value="value"
                label="Vælg tildelt"
                outlined
                dense
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="referredOnModel.data.comment"
                label="Kommentar til sagsforløb"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="referredOnDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="referredOnDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogReferredOn",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      referredOnModel: Object,
      referredOnDialog: false,
      loaded: false,
      saving: false,
      hasChanged: false,
      timeProps: {
        format: "24hr",
        useSeconds: true,
      },
    };
  },
  methods: {
    referredOnDialogInit: function () {
      var vm = this;
      vm.loaded = false;
      vm.referredOnDialog = true;

      vm.$AuthService.api
        .get(`supportticket/ReferredOn/${this.$route.params.id}`)
        .then((response) => {
          vm.referredOnModel = response.data;
          vm.loaded = true;
        });
    },
    referredOnDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/ReferredOn/${this.$route.params.id}`,
          this.referredOnModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.ReferredOnDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    referredOnDialogCancel: function () {
      this.referredOnModel = null;
      this.referredOnDialog = false;
      this.hasChanged = false;
    },
  },
};
</script>