<template>
  <v-dialog
    v-model="followersDialog"
    persistent
    scrollable
    max-width="800"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :title="$t('supportTickets.dialogFollowers.title')"
        v-on="on"
        small
        @click="followersDialogInit"
      >
        <v-icon>
          mdi-account-switch-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        {{ $t('supportTickets.dialogFollowers.title') }}
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />

        <v-container
          v-if="loaded"
          fluid
        >
          <v-row>
            <v-col>
              <h2>{{ $t('supportTickets.dialogFollowers.persons.title') }}</h2>
              <vMultiselectListbox 
                v-model="selectedPersons"
                :options="followersModel.metadata.persons"
                :reduce-display-property="(option) => option.text"
                :reduce-value-property="(option) => option.value"
                :search-options-placeholder="$t('supportTickets.dialogFollowers.persons.searchOptions')"
                :selected-options-placeholder="$t('supportTickets.dialogFollowers.persons.searchSelected')"
                :no-options-text="$t('supportTickets.dialogFollowers.persons.noOption')"
                :selected-no-options-text="$t('supportTickets.dialogFollowers.persons.noSelected')"
                :no-options-found-text="$t('supportTickets.dialogFollowers.persons.noOptionFound')"
                :no-selected-options-found-text="$t('supportTickets.dialogFollowers.persons.noSelectedFound')"
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-if="loaded"
          fluid
        >
          <v-row>
            <v-col>
              <h2>{{ $t('supportTickets.dialogFollowers.departments.title') }}</h2>
              <vMultiselectListbox 
                v-model="selectedDepartments"
                :options="followersModel.metadata.departments"
                :reduce-display-property="(option) => option.text"
                :reduce-value-property="(option) => option.value"
                :search-options-placeholder="$t('supportTickets.dialogFollowers.departments.searchOptions')"
                :selected-options-placeholder="$t('supportTickets.dialogFollowers.departments.searchSelected')"
                :no-options-text="$t('supportTickets.dialogFollowers.departments.noOption')"
                :selected-no-options-text="$t('supportTickets.dialogFollowers.departments.noSelected')"
                :no-options-found-text="$t('supportTickets.dialogFollowers.departments.noOptionFound')"
                :no-selected-options-found-text="$t('supportTickets.dialogFollowers.departments.noSelectedFound')"
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="followersDialogCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="followersDialogSave"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'SupportTicketDialogFollowers',
  components: {
    Loading
  },
  data() {
    return {
      followersModel: Object,
      followersDialog: false,
      loaded: false,
      saving: false,
      hasChanged: false,
      selectedPersons: [],
      selectedDepartments: []
    };
  },
  methods: {
    followersDialogInit: async function () {
      var vm = this;
      vm.hasChanged = false;
      var response = await vm.$AuthService.api.get(`supportticket/changefollowers/${this.$route.params.id}`);
      vm.followersModel = response.data;
      this.selectedPersons = vm.followersModel.data.followerPersonIds.map(x => vm.followersModel.metadata.persons.find(y => y.value === x));
      this.selectedDepartments = vm.followersModel.data.followerDepartmentIds.map(x => vm.followersModel.metadata.departments.find(y => y.value === x));
      vm.followersDialog = true;
      vm.loaded = true;
    },
    followersDialogSave: async function () {
      var vm = this;
      vm.saving = true;

      var response = await vm.$AuthService.api.put(`supportticket/changefollowers/${this.$route.params.id}`, {
          followerDepartmentIds: this.selectedDepartments.map(x => x.value),
          followerPersonIds: this.selectedPersons.map(x => x.value)
        });
      if (response.data.success) {
        vm.followersDialog = false;
        vm.saving = false;
        this.$emit("saved");
      }
    },
    followersDialogCancel: function () {
      this.followersDialog = false;
      this.loaded = false;
    },
  }
};
</script>