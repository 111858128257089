<template>
  <v-dialog
    v-model="changeAssignedToDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        @click="changeAssignedToDialogInit"
      >
        <v-icon>
          mdi-account-switch-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card v-if="loaded">
      <v-card-title class="title white--text secondary">
        Ændring af tildelt
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />
        <v-container
          v-if="loaded && changeAssignedToModel"
          fluid
        >
          <v-row>
            <v-col>
              Aktuelt tildelt: <br>
              {{ changeAssignedToModel.data.currentAssignedTo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="changeAssignedToModel.data.newAssignedToId"
                :items="changeAssignedToModel.metadata.assignedTos"
                item-text="text"
                item-value="value"
                label="Ny tildelt"
                outlined
                dense
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="changeAssignedToModel.data.comment"
                label="Kommentar til sagsforløb"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="changeAssignedToDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="changeAssignedToDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogAssignedTo",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      changeAssignedToModel: Object,
      changeAssignedToDialog: false,
      hasChanged: false,
      loaded: false,
      saving: false
    };
  },
  methods: {
    changeAssignedToDialogInit: function () {
      var vm = this;

      vm.$AuthService.api
        .get(`supportticket/changeAssignedTo/${this.$route.params.id}`)
        .then((response) => {
          vm.changeAssignedToModel = response.data;
          vm.changeAssignedToDialog = true;
          vm.loaded = true;
        });
    },
    changeAssignedToDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/changeAssignedTo/${this.$route.params.id}`,
          this.changeAssignedToModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.changeAssignedToDialog = false;
            vm.hasChanged = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    changeAssignedToDialogCancel: function () {
      this.changeAssignedToModel = null;
      this.changeAssignedToDialog = false;
      this.hasChanged = false;
      this.loaded = false;
    },
  },
};
</script>