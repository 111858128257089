<template>
  <v-dialog
    v-model="endedOnDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        title="Afhjælpning slut"
        v-on="on"
        @click="endedOnDialogInit"
      >
        <v-icon>mdi-clock-end</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Afhjælpning slut
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />

        <v-container
          v-if="loaded && endedOnModel"
          fluid
        >
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="endedOnModel.data.endedOn"
                use-iso
                label="Evt. tidspunkt..."
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="endedOnModel.data.comment"
                label="Kommentar"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="endedOnDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!isValid"
          @click="endedOnDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  name: "SupportTicketDialogEndedOn",
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      endedOnModel: Object,
      endedOnDialog: false,
      loaded: false,
      saving: false,
      isValid: false,
      timeProps: {
        format: "24hr",
        useSeconds: true,
      },
    };
  },
  watch: {
    endedOnModel: {
      deep: true,
      handler() {
        const { comment } = this.endedOnModel.data;

        this.isValid = comment;
      }
    }
  },
  methods: {
    endedOnDialogInit: function () {
      var vm = this;
      vm.endedOnDialog = true;

      vm.$AuthService.api
        .get(`supportticket/endedon/${this.$route.params.id}`)
        .then((response) => {
          vm.endedOnModel = response.data;
          vm.loaded = true;
        });
    },
    endedOnDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/endedon/${this.$route.params.id}`,
          this.endedOnModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.endedOnDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    endedOnDialogCancel: function () {
      this.endedOnModel = null;
      this.endedOnDialog = false;
      this.loaded = false;
    },
  }
};
</script>