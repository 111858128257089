<template>
  <v-dialog
    v-model="awaitDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        title="Start eller afslut 'afventer kunde' (pause)"
        v-on="on"
        @click="awaitDialogInit"
      >
        <v-icon>
          mdi-timer-sand
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Afventer kunde (pause)
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />
        <v-container
          v-if="loaded && awaitModel"
          fluid
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="awaitModel.data.title"
                label="Titel"
                outlined
              />
              <v-textarea
                v-model="awaitModel.data.text"
                label="Detaljer (hvorfor osv)"
                outlined
              />
              <v-datetime-picker
                v-model="awaitModel.data.startedOn"
                use-iso
                label="Start"
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
              <v-datetime-picker
                v-model="awaitModel.data.followUpOn"
                use-iso
                label="Opfølgning"
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
              <v-datetime-picker
                v-model="awaitModel.data.finishedOn"
                use-iso
                label="Afsluttet"
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="awaitDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="awaitDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogWaiting",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      awaitModel: Object,
      awaitDialog: false,
      loaded: false,
      saving: false,

      timeProps: {
        format: "24hr",
        useSeconds: true,
      },
    };
  },
  methods: {
    awaitDialogInit: function () {
      var vm = this;

      vm.$AuthService.api
        .get(`supportticket/await/${this.$route.params.id}`)
        .then((response) => {
          vm.awaitModel = response.data;
          vm.awaitDialog = true;
          vm.loaded = true;
        });
    },
    awaitDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/await/${this.$route.params.id}`,
          this.awaitModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.awaitDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    awaitDialogCancel: function () {
      this.awaitModel = null;
      this.awaitDialog = false;
      this.loaded = false;
    },
  },
};
</script>