<template>
  <v-dialog
    v-model="changeCalledByDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        @click="changeCalledByDialogInit"
      >
        <v-icon>
          mdi-account-switch-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Ændring af Rekvirent
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />
        <v-container
          v-if="loaded && changeCalledByModel"
          fluid
        >
          <v-row>
            <v-col>
              Aktuel rekvirent: <br>
              {{ changeCalledByModel.data.currentCalledBy }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="changeCalledByModel.data.newCalledById"
                :items="changeCalledByModel.metadata.calledBys"
                item-text="text"
                item-value="value"
                label="Ny rekvirent"
                outlined
                dense
                @change="hasChanged = true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="changeCalledByModel.data.comment"
                label="Kommentar til sagsforløb"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="loaded">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="changeCalledByDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="changeCalledByDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogCalledBy",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      changeCalledByModel: Object,
      changeCalledByDialog: false,
      hasChanged: false,
      loaded: false,
      saving: false
    };
  },
  methods: {
    changeCalledByDialogInit: function ()  {
      var vm = this;

      vm.$AuthService.api
        .get(`supportticket/changeCalledBy/${this.$route.params.id}`)
        .then((response) => {
          vm.changeCalledByModel = response.data;
          vm.changeCalledByDialog = true;
          vm.loaded = true;
        });
    },
    changeCalledByDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/changeCalledBy/${this.$route.params.id}`,
          this.changeCalledByModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.changeCalledByDialog = false;
            vm.hasChanged = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    changeCalledByDialogCancel: function () {
      // Reset to initial values
      this.changeCalledByModel = null;
      this.changeCalledByDialog = false;
      this.hasChanged = false;
      this.loaded = false;
    },
  },
};
</script>