<template>
  <v-dialog
    v-model="startedOnDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        title="Afhjælpning start"
        v-on="on"
        @click="startedOnDialogInit"
      >
        <v-icon>mdi-clock-start</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Afhjælpning start
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />

        <v-container
          v-if="loaded && startedOnModel"
          fluid
        >
          <v-row align="center">
            <v-col class="d-flex align-center">
              <v-datetime-picker
                v-model="startedOnModel.data.startedOn"
                use-iso
                label="Evt. tidspunkt..."
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
                @input="hasChanged = true"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>

              <v-btn
                class="ml-4"
                outlined
                small
                @click="startedOnModel.data.startedOn = new Date(); hasChanged = true"
              >
                Nu
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="startedOnModel.data.comment"
                label="Kommentar til sagsforløb"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="startedOnDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="startedOnDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogStartedOn",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      startedOnModel: Object,
      startedOnDialog: false,
      loaded: false,
      saving: false,
      hasChanged: false,
      timeProps: {
        format: "24hr",
        useSeconds: true,
      },
    };
  },
  methods: {
    startedOnDialogInit: function () {
      var vm = this;
      vm.loaded = false;
      vm.hasChanged = false;
      vm.startedOnDialog = true;

      vm.$AuthService.api
        .get(`supportticket/startedon/${this.$route.params.id}`)
        .then((response) => {
          vm.startedOnModel = response.data;
          vm.loaded = true;
        });
    },
    startedOnDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/startedon/${this.$route.params.id}`,
          this.startedOnModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.startedOnDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    startedOnDialogCancel: function () {
      this.startedOnModel = null;
      this.startedOnDialog = false;
    },
  },
};
</script>