<template>
  <v-dialog
    v-model="reopenDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="$store.getters.isInternal"
        small
        v-bind="attrs"
        title="Genåbning af sag"
        v-on="on"
        @click="reopenDialogInit"
      >
        <v-icon dark>
          mdi-undo-variant
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Genåbning af sag
      </v-card-title>
      <v-card-text>
        <Loading :loading="!loaded" />

        <v-container
          v-if="loaded && reopenModel"
          fluid
        >
          <v-row>
            <v-col>
              <v-textarea
                v-model="reopenModel.data.comment"
                label="Kommentar"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="reopenDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="reopenDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: "SupportTicketDialogReopen",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      reopenModel: Object,
      reopenDialog: false,
      loaded: false,
      saving: false
    };
  },
  methods: {
    reopenDialogInit: function () {
      var vm = this;
      vm.reopenDialog = true;

      vm.$AuthService.api
        .get(`supportticket/reopen/${this.$route.params.id}`)
        .then((response) => {
          vm.reopenModel = response.data;
          vm.loaded = true;
        });
    },
    reopenDialogSave: function () {
      var vm = this;
      vm.saving = true;

      vm.$AuthService.api
        .put(
          `supportticket/reopen/${this.$route.params.id}`,
          this.reopenModel.data
        )
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.reopenDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
          }
        });
    },
    reopenDialogCancel: function () {
      this.reopenModel = null;
      this.reopenDialog = false;
      this.loaded = false;
    },
  },
};
</script>