<template>
  <v-container fluid>
    <Loading :loading="!loaded" />

    <div v-if="loaded">
      <v-row>
        <v-col>
          <SupportTicketCard
            :item="model.data"
            :metadata="model.metadata"
          />
        </v-col>
      </v-row>
      
      <!-- evaluation form -->
      <v-row v-if="false && model.data.closedOn && !model.data.evaluation.evaluatedById">
        <v-col>
          <v-card
            elevation="10"
            class="mx-auto my-12"
            max-width="800"
          >
            <v-toolbar
              color="purple"
              dark
            >
              <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
              <v-toolbar-title>Evaluering</v-toolbar-title>
              <v-spacer />
              <!-- <v-icon>mdi-elevator</v-icon>  -->
            </v-toolbar>
            <v-spacer />
            <v-card-subtitle>
              Vurder venligst din oplevelse
            </v-card-subtitle>
            <v-card-text>
              <v-row
                v-for="(question,i) in model.data.evaluation.questions"
                :key="i"
              >
                <v-col cols="4">
                  {{ question.text }}
                </v-col>
                <v-col cols="4">
                  <v-slider
                    v-model="question.response"
                    color="purple"
                    thumb-label="always"
                    :max="question.scoreMax"
                    :min="question.scoreMin"
                    step="1"
                  />
                </v-col>
                <v-col cols="4">
                  Besvar hvor {{ question.scoreMin }} er lavest og {{ question.scoreMax }} er højst
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="model.data.evaluation.comment"
                    label="Kommentar"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col align="right">
                  <v-btn
                    dark
                    color="purple"
                    @click="evaluate"
                  >
                    Gem evaluering
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
      <!-- evaluate show form -->
      <v-row v-if="false && model.data.closedOn && !!model.data.evaluation.evaluatedById">
        <v-col>
          <v-card elevation="2">
            <v-card-title>Evaluering</v-card-title>
            <v-card-text>
              <v-row
                v-for="(question,i) in model.data.evaluation.questions"
                :key="i"
              >
                <v-col cols="4">
                  {{ question.text }}
                </v-col>
                <v-col cols="4">
                  <v-slider
                    v-model="question.response"
                    thumb-label="always"
                    :max="question.scoreMax"
                    readonly
                    :min="question.scoreMin"
                    step="1"
                  />
                </v-col>
                <v-col cols="4">
                  Besvar hvor {{ question.scoreMin }} er lavest og {{ question.scoreMax }} er højst
                </v-col>
              </v-row>
              <v-row>
                Kommentar:<br>
                {{ model.data.evaluation.comment }}
              </v-row>
              <v-row>
                Indsendt af {{ model.data.evaluation.evaluatedByFullNameWithDetails }} @ {{ model.data.evaluation.evaluatedOn | formatDateTime }}
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Sagsoplysninger -->
      <v-row>
        <v-col>
          <v-card elevation="2">
            <v-toolbar flat>
              <v-toolbar-title>
                Sagsoplysninger
              </v-toolbar-title>

              <v-spacer />

              <v-menu v-if="user.organizationKind == 'Internal'">
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    fab
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader>Supportsag</v-subheader>
                  <v-list-item-group color="primary">
                    <SupportTicketEditDialog @saved="init" />
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>
            <!-- <div class="flex-grow-1"></div> -->
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr>
                          <th>Lokation</th>
                          <td class="text-right"><LocationLink :id="model.data.locationId" /></td>
                        </tr>                        <tr>
                          <th>Support sagsnr</th>
                          <td class="text-right">{{ model.data.code }}</td>
                        </tr>
                        <tr>
                          <th>Ekstern reference</th>
                          <td class="text-right">{{ model.data.externalCode }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr>
                          <th class="d-flex align-center">
                            <v-icon>mdi-account-outline</v-icon>
                            Rekvirent
                          </th>
                          <td class="text-caption">
                            {{ model.data.calledByFullName }} ({{ model.data.calledByOrganizationTitle }}, {{ model.data.calledByTitle }})
                          </td>
                          <td class="text-right">
                            <SupportTicketDialogCalledBy
                              v-if="!model.data.closedOn && (user.id == model.data.calledById || user.permissions.includes('AdministratorSupportTicket'))"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th class="d-flex align-center">
                            <v-icon>mdi-account-outline</v-icon>
                            Aktuelt tildelt
                          </th>
                          <td class="text-caption">
                            {{ model.data.assignedToFullName }} ({{ model.data.assignedToOrganizationTitle }})
                          </td>
                          <td class="text-right">
                            <SupportTicketDialogAssignedTo
                              v-if="!model.data.closedOn && (user.id == model.data.assignedToId || user.permissions.includes('AdministratorSupportTicket'))"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th class="d-flex align-center">
                            <v-icon>mdi-account-multiple-outline</v-icon>
                            Følgere
                          </th>
                          <td>
                            <div
                              v-for="item in model.data.followerPersons"
                              :key="item.id"
                              class="text-caption"
                            >
                              {{ item.fullNameWithDetails }}
                            </div>
                            <div
                              v-for="item in model.data.followerDepartments"
                              :key="item.id"
                              class="text-caption"
                            >
                              {{ item.title }}
                            </div>
                          </td>
                          <td class="text-right">
                            <SupportTicketDialogFollowers
                              v-if="!model.data.closedOn"
                              @saved="init"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr>
                          <th>Afventer</th>
                          <td class="text-right">
                            <span v-if="model.data.awaitingCustomer">Afventer kunde</span>
                            <SupportTicketDialogWaiting
                              v-if="user.organizationKind == 'Internal' && !model.data.closedOn && (user.id == model.data.calledById || user.id == model.data.assignedToId || user.permissions.includes('AdministratorSupportTicket'))"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Registreret</th>
                          <td class="text-right">{{ model.data.createdOn | formatDateTime }}</td>
                        </tr>
                        <tr>
                          <th>Visiteret</th>
                          <td class="text-right">
                            {{ model.data.referredOn | formatDateTime }}
                            <SupportTicketDialogReferredOn
                              v-if="user.organizationKind == 'Internal' && !model.data.referredOn && !model.data.closedOn"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Afhjælpning start</th>
                          <td class="text-right">
                            {{ model.data.startedOn | formatDateTime }}
                            <SupportTicketDialogStartedOn
                              v-if="user.organizationKind == 'Internal' && model.data.referredOn && !model.data.startedOn && !model.data.closedOn"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Afhjælpning slut</th>
                          <td class="text-right">
                            {{ model.data.endedOn | formatDateTime }}
                            <SupportTicketDialogEndedOn
                              v-if="user.organizationKind == 'Internal' && model.data.startedOn && !model.data.endedOn && !model.data.closedOn"
                              @saved="init"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Afsluttet</th>
                          <td class="text-right">
                            <SupportTicketDialogReopen
                              v-if="model.data.closedOn"
                              @saved="init"
                            />
                            <SupportTicketDialogClose
                              v-if="!model.data.closedOn"
                              @saved="init"
                            />
                            <span class="ml-2">
                              {{ model.data.closedOn | formatDateTime }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="model.data.closeCreatedTaskRequestId">
                          <th>Overført til opgavebank:</th>
                          <td class="text-right">
                            <router-link :to="`/taskrequest/${model.data.closeCreatedTaskRequestId}`">
                              {{ model.data.closeCreatedTaskRequestCodeAndTitle }}
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="12"
          lg="6"
        >
          <v-card elevation="2">
            <v-card-title>Sagsdetaljer</v-card-title>
            <v-card-text>
              <!-- overveje at bruge https://vuetifyjs.com/en/components/dividers/#subheaders -->
              <b>{{ model.data.title }}</b>
              <div v-html="model.data.text" />
              <br v-if="model.data.contactDetails">
              <div
                v-if="model.data.contactDetails"
                v-html="model.data.contactDetails"
              />
            </v-card-text>
          </v-card>
          <v-card
            elevation="2"
            class="mt-6"
          >
            <v-card-title>Filer</v-card-title>
            <v-card-text>
              <FileList
                base-url="/supportticket/file"
                :files="model.data.files"
                :deletable="user.id == model.data.calledById || user.id == model.data.assignedToId || user.permissions.includes('AdministratorSupportTicket')"
              />

              <v-file-input
                v-model="newFiles"
                counter
                multiple
                show-size
                truncate-length="25"
                accept=".doc,.docx,application/msword,image/*,application/pdf,.pdf"
              /> <!-- hvilke filtyper? -->
              <div align="right">
                <v-btn
                  outlined
                  :disabled="newFiles.length==0"
                  @click="filesAdd"
                >
                  Upload
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          xs="12"
          sm="12"
          md="12"
          lg="6"
        >
          <ActivityLog
            :add-endpoint="`/SupportTicket/${$route.params.id}/Entry`"
            :entry-template="model.data.entryTemplate"
            :entries="model.data.entries"
            :closed="!!model.data.closedOn"
            :relationId="$route.params.id"
            relationKind="SupportTicket"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import FileList from '@/components/FileList';
import LocationLink from '@/components/LocationLink.vue'
import SupportTicketCard from '@/components/support-ticket/SupportTicketCard'
import SupportTicketDialogCalledBy from '@/components/support-ticket/SupportTicketDialogCalledBy.vue'
import SupportTicketDialogAssignedTo from '@/components/support-ticket/SupportTicketDialogAssignedTo.vue'
import SupportTicketDialogWaiting from '@/components/support-ticket/SupportTicketDialogWaiting.vue'
import SupportTicketDialogReopen from '@/components/support-ticket/SupportTicketDialogReopen.vue'
import SupportTicketDialogClose from '@/components/support-ticket/SupportTicketDialogClose.vue'
import SupportTicketDialogFollowers from '@/components/support-ticket/SupportTicketDialogFollowers.vue'
import SupportTicketDialogReferredOn from '@/components/support-ticket/SupportTicketDialogReferredOn.vue'
import SupportTicketDialogStartedOn from '@/components/support-ticket/SupportTicketDialogStartedOn.vue'
import SupportTicketDialogEndedOn from '@/components/support-ticket/SupportTicketDialogEndedOn.vue'
import SupportTicketEditDialog from '@/components/support-ticket/SupportTicketEditDialog.vue'
import Loading from '@/components/Loading.vue'
import ActivityLog from '@/components/ActivityLog.vue';

export default {
  
  name: 'SupportTicketDetail',
  components: {
    FileList,
    LocationLink,
    SupportTicketCard,
    SupportTicketDialogCalledBy, 
    SupportTicketDialogAssignedTo, 
    SupportTicketDialogWaiting,
    SupportTicketDialogReopen,
    SupportTicketDialogClose,
    SupportTicketDialogFollowers,
    SupportTicketDialogReferredOn,
    SupportTicketDialogStartedOn,
    SupportTicketDialogEndedOn,
    SupportTicketEditDialog,
    Loading,
    ActivityLog
  },
  beforeRouteLeave(to, from, next) {
    if (this.model.data.entryTemplate.text !== '') {
      const answer = window.confirm('Vil du navigere væk? Din besked vil ikke blive gemt.');

      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data() {
    return {
      model: Object,
      //view only data
      loaded: false,
      newFiles: [],

      timeProps: {
        format:"24hr",
        useSeconds: true,
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    }
  },
  created() {
    var vm = this;
    vm.init();
  },
  methods: {
    init: function() {
      var vm = this;
      vm.$AuthService.api.get(`supportticket/detail/${this.$route.params.id}` )
        .then(response => {
          this.model = response.data;
          this.loaded = true;
      });
    },
    evaluate: function() {
      var vm = this;

      vm.$AuthService.api.put(`supportticket/evaluate/${this.$route.params.id}`, this.model.data.evaluation)
      .then(response => {
        if (response.data.success) {
          vm.init();
        }
        else {
          //handle...
        }
      });
    },
    filesAdd() {
      var vm = this;
      
      let formData = new FormData();

      for (let file of vm.newFiles) {
          formData.append("files", file, file.name);
      }

      vm.$AuthService.api.post(`/SupportTicket/${vm.model.data.id}/Files`, formData, { headers: {'Content-Type': 'multipart/form-data'} })
      .then(response => {
        if (response.data.success) {
          vm.model.data.files = response.data.model;
          vm.newFiles = [];
        }
      });
    },
    fileRemove(id) {      
      if (!confirm('Er du sikker på at du vil slette denne fil?')) {
        return;
      }

      let vm = this;
      vm.$AuthService.api.delete(`/SupportTicket/File/${id}`)
      .then(response => {
        if (response.data.success) {
          vm.model.data.files = response.data.model;
        }
      });
    },
    
    addEntryKeyDown(e) {
      let vm = this;

      if (e.key==="Escape") {
        vm.model.data.entryTemplate.text = '';
        vm.model.data.entryTemplate.entryDateTime = null;
        vm.model.data.entryTemplate.isInternal = false;
      }

      if ((e.key === "s" && e.altKey) || (e.keyCode === 13 && e.ctrlKey)) {
        e.preventDefault();
        vm.addEntry();
      }
    },
    addEntry() {
      var vm = this;
      vm.addingNote = true;

      if (!vm.model.data.entryTemplate.text)
        return;
      
      vm.$AuthService.api.post(`/SupportTicket/${vm.model.data.id}/Entry`, vm.model.data.entryTemplate)
      .then(response => {
        if (response.data.success) {
          vm.model.data.entries.unshift(response.data.model);
          vm.model.data.entryTemplate.text = '';
          vm.model.data.entryTemplate.entryDateTime = null;
          vm.model.data.entryTemplate.isInternal = false;
        }
      })
      .finally(() => {
        vm.addingNote = false;
      });
    }
  }
}

</script>