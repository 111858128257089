<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <template #activator="{ attrs, on }">
      <v-list-item
        :disabled="!$store.getters.currentUser.permissions.includes('AdministratorSupportTicket')"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Rediger supportsag</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        Ændring af support ticket detaljer
      </v-card-title>

      <Loading :loading="loading" />

      <v-card-text class="pt-4">
        <v-container
          v-if="model"
          fluid
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="model.data.category"
                :items="model.metadata.categories"
                item-text="text"
                item-value="value"
                label="Kategori"
                return-object
                :search-input.sync="categoryInput"
                auto-select-first
                small-chips
                outlined
dense @input="categoryInput = null"
              />
              <v-autocomplete
                v-model="model.data.location"
                :items="model.metadata.locations"
                item-text="text"
                item-value="value"
                label="Lokation"
                return-object
                :search-input.sync="locationInput"
                auto-select-first
                small-chips
                outlined
dense @input="locationInput = null"
              />
              <v-autocomplete
                v-model="model.data.department"
                :items="model.metadata.departments"
                item-text="text"
                item-value="value"
                label="Enhed"
                return-object
                :search-input.sync="departmentInput"
                auto-select-first
                small-chips
                outlined
dense @input="departmentInput = null"
              />
              <v-autocomplete
                v-model="model.data.subjectAreas"
                :items="model.metadata.subjectAreas"
                item-text="text"
                item-value="value"
                label="Teknisk område"
                return-object
                :search-input.sync="subjectInput"
                auto-select-first
                small-chips
                outlined
dense multiple @input="subjectInput = null"
              />
              <v-text-field
                v-model="model.data.title"
                label="Titel"
                outlined
              />
              <v-text-field
                v-model="model.data.externalCode"
                label="Ekstern reference"
                outlined
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="model.data.text"
                label="Beskrivelse..."
                outlined
              />
              <v-textarea
                v-model="model.data.contactDetails"
                label="Kontaktoplysninger..."
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="dialog = false"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'SupportTicketEditDialog',
  components: {
    Loading
  },
  data() {
    return {
      dialog: false,
      model: null,
      loading: false,
      saving: false,
      categoryInput: null,
      locationInput: null,
      departmentInput: null,
      subjectInput: null
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`supportticket/edit/${this.$route.params.id}`);
        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.saving = true;

      try {
        await this.$AuthService.api.put(`supportticket/edit/${this.$route.params.id}`, this.model.data);

        this.dialog = false;
        this.$emit('saved');
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>